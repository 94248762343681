var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-access-modal"
  }, [_c('div', {
    staticClass: "sygni-access-modal__bg"
  }), _c('div', {
    staticClass: "sygni-access-modal__box"
  }, [_c('div', {
    staticClass: "sygni-access-modal__top"
  }, [_vm._m(0), _c('div', {
    staticClass: "sygni-access-modal__title"
  }, [_c('h2', {
    staticClass: "sygni-access-modal__header"
  }, [_vm._v("Access denied")]), _c('div', {
    staticClass: "sygni-access-modal__icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('LOCK RED'),
      "alt": "Access denied"
    }
  })])])]), _c('div', {
    staticClass: "sygni-access-modal__body"
  }, [_vm._m(1), _c('div', {
    staticClass: "sygni-access-modal__actions"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled red",
    on: {
      "click": function click($event) {
        return _vm.$router.push('/welcome');
      }
    }
  }, [_vm._v("Go to purchase")])], 1)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-access-modal__logo"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/logo.png"),
      "alt": "Fundequate"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "sygni-access-modal__desc"
  }, [_vm._v(" To access this site "), _c('br'), _vm._v("you must have purchased "), _c('br'), _vm._v("a higher package. ")]);
}]

export { render, staticRenderFns }