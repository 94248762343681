var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marketplace-filters filters-container filters table"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'COMPANY NAME'
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "secondary border-collapse",
    attrs: {
      "label": 'DATE',
      "type": "month",
      "disabled-date": _vm.disabledDate,
      "placeholder": _vm.date
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": [],
      "label": 'INDUSTRY'
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": [],
      "label": 'STAGE'
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": [],
      "label": 'INSTRUMENT CLASS'
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": [],
      "label": 'INSTRUMENT TYPE'
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": [],
      "label": 'REGISTRATION'
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-2"
  }, [_c('div', {
    staticClass: "filters__actions"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled black",
    attrs: {
      "selectable": false
    }
  }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ")")]), _c('div', {
    staticClass: "clear-all-button"
  }, [_vm._v("Clear all")])], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }